import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import showdown from 'showdown';
import * as styles from './privacy.module.scss';

const Privacy = ({ privacy }) => {
  const converter = new showdown.Converter();

  return (
    <Container>
      <Row className={styles.section}>
        <Col>
          <h3 className="title">{privacy.title}</h3>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(privacy.policy)
              }}
            />
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default Privacy;
